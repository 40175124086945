import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import styles from './Layout.module.scss';
import Sidebar from '../Sidebar/Sidebar';
import { NavLink, useLocation } from 'react-router-dom';
import cx from 'classnames';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import useWindowSize from '../../hooks/useWindowSize/useWindowSize';
import Navigation from '../Navigation/Navigation';
import { routes } from '../../config/Router/routes';
import {
  faBoxesPacking,
  faCalendar,
  faChildren,
  faGlobe,
  faHouseChimney,
  faMedal,
  faMoneyBill,
  faPeopleGroup,
  faUserGroup,
  faUsers,
  faShop,
  faClock,
  faBusinessTime,
  faTag,
} from '@fortawesome/free-solid-svg-icons';
import Modal from '../Modal/Modal';
import CurrentAccount from '../CurrentAccount/CurrentAccount';
import { Branch } from '../../domain/Branch';
import * as locationService from '../../store/branch/service';
import * as branchService from '../../store/branch/service';
import { ThunkDispatch } from 'redux-thunk';
import { StoreState } from '../../config/StoreProvider/StoreProvider';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import { translate } from '../../utility/messageTranslator/translate';
import BranchSelectForm from '../../component/Admin/Branch/BranchSelectForm/BranchSelectForm';
import { User } from '../../domain/User';
import { Roles } from '../../domain/Role';
import AuthLayout from './AuthLayout/AuthLayout';
import ParentLayout from './ParentLayout/ParentLayout';

export type Props = {
  children: ReactNode;
  isAuthenticated: boolean;
  branchOptions: Branch[];
  onBranchOptionsFetch: () => void;
  selectedBranch: number | null;
  currentUser: User | null;
  onBranchSelect: (branchId: number) => void;
};

export type NavigationItem = {
  label: string;
  to: string;
  icon?: IconProp;
  roles: Roles[];
};

export type NavigationGroup = {
  label: string;
  items: NavigationItem[];
  roles: Roles[];
};

const MOBILE_BREAK_POINT = 900;

const Layout = ({
  children,
  isAuthenticated,
  branchOptions,
  onBranchOptionsFetch,
  selectedBranch,
  currentUser,
  onBranchSelect,
}: Props) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation();
  const intl = useIntl();

  const { width } = useWindowSize();

  const SIDEBAR_ITEMS = [
    {
      label: translate(intl, 'NAVIGATION.GROUP_GLOBAL'),
      roles: [Roles.ADMIN, Roles.MANAGER, Roles.EMPLOYEE],
      items: [
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_BRANCHES'),
          to: routes.branches.list,
          icon: faHouseChimney as IconProp,
          roles: [Roles.ADMIN],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_EMPLOYEES'),
          to: routes.employees.list,
          icon: faUsers as IconProp,
          roles: [Roles.ADMIN, Roles.MANAGER],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_CHILDREN'),
          to: routes.children.list,
          icon: faChildren as IconProp,
          roles: [Roles.ADMIN, Roles.MANAGER],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_ATTENDANCES'),
          to: routes.attendances,
          icon: faCalendar as IconProp,
          roles: [Roles.ADMIN, Roles.MANAGER, Roles.EMPLOYEE],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_ATTENDANCE_PACKAGES'),
          to: routes.attendancePackages.list,
          icon: faBoxesPacking as IconProp,
          roles: [Roles.ADMIN],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_ACHIEVEMENTS'),
          to: routes.achievements,
          icon: faMedal as IconProp,
          roles: [Roles.ADMIN, Roles.MANAGER, Roles.EMPLOYEE],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_PAYMENTS'),
          to: routes.payments,
          icon: faMoneyBill as IconProp,
          roles: [Roles.ADMIN, Roles.MANAGER],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_ADDITIONAL_SERVICES'),
          to: routes.additionalService.list,
          icon: faShop as IconProp,
          roles: [Roles.ADMIN],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_WANTED_TIMES'),
          to: routes.subscribedTimes,
          icon: faClock as IconProp,
          roles: [Roles.ADMIN, Roles.MANAGER],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_TIME_OFFS'),
          to: routes.timeOffs,
          icon: faBusinessTime as IconProp,
          roles: [Roles.ADMIN],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_DISCOUNTS'),
          to: routes.discounts,
          icon: faTag as IconProp,
          roles: [Roles.ADMIN],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_USERS'),
          to: routes.users.list,
          icon: faUserGroup as IconProp,
          roles: [Roles.ADMIN],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_TRANSLATIONS'),
          to: routes.translations,
          icon: faGlobe as IconProp,
          roles: [Roles.ADMIN],
        },
      ],
    },
  ];

  useEffect(() => {
    if (isMobileMenuOpen) {
      window.scroll({ top: 0 });
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isMobileMenuOpen]);

  useEffect(() => {
    if (isAuthenticated && currentUser?.role !== Roles.PARENT) {
      onBranchOptionsFetch();
    }
  }, []);

  const branch = useMemo(() => {
    if (!selectedBranch || !branchOptions.length) {
      return null;
    }

    return branchOptions.find(
      (locationOption) => locationOption.id === selectedBranch,
    );
  }, [selectedBranch, branchOptions]);

  useEffect(() => {
    if (!selectedBranch && branchOptions.length) {
      onBranchSelect(branchOptions[0].id);

      return;
    }

    if (
      selectedBranch &&
      branchOptions.length &&
      !branchOptions.find((branch) => branch.id === selectedBranch)
    ) {
      onBranchSelect(branchOptions[0].id);

      return;
    }
  }, [branchOptions, selectedBranch]);

  useEffect(() => {
    if (selectedBranch) {
      setIsModalOpen(false);
    }
  }, [selectedBranch]);

  useEffect(() => {
    setTimeout(() => {
      setIsMobileMenuOpen(false);
      window.scroll(0, 0);
    }, 100);
    // @ts-ignore
    window?.dataLayer?.push({
      event: 'pageview',
    });
  }, [location.key]);

  const getNavigationGroups = () => {
    if (!currentUser) {
      return [];
    }

    let sideBarItems = SIDEBAR_ITEMS;

    if (branch) {
      sideBarItems = [
        {
          label: translate(intl, 'NAVIGATION.GROUP_BRANCH_SETTINGS'),
          roles: [Roles.ADMIN, Roles.MANAGER],
          items: [
            {
              label: translate(intl, 'NAVIGATION.SIDEBAR_WORK_TIMES'),
              to: routes.workTimes.list,
              icon: faPeopleGroup as IconProp,
              roles: [Roles.ADMIN, Roles.MANAGER],
            },
            {
              label: translate(intl, 'NAVIGATION.SIDEBAR_WORK_TIME_CALENDAR'),
              to: routes.workTimes.calendar,
              icon: faCalendar as IconProp,
              roles: [Roles.ADMIN, Roles.MANAGER],
            },
          ],
        },
        ...sideBarItems,
      ];
    }

    return sideBarItems.filter((item) => item.roles.includes(currentUser.role));
  };

  const getCurrentBranch = () => {
    if (
      !currentUser ||
      currentUser.role === Roles.EMPLOYEE ||
      currentUser.role === Roles.PARENT
    ) {
      return null;
    }

    return (
      <CurrentAccount
        key={branch?.id}
        img={branch?.image?.thumbLocation ?? ''}
        title={branch?.name ?? translate(intl, 'NAVIGATION.NO_BRANCH_SELECTED')}
        subTitle={branch ? '' : translate(intl, 'NAVIGATION.CLICK_TO_SELECT')}
        onClick={() => !isModalOpen && setIsModalOpen(true)}
      >
        <Modal
          onClose={() => setIsModalOpen(false)}
          isOpen={isModalOpen}
          title={translate(intl, 'NAVIGATION.SELECT_BRANCH')}
          className={styles.branchSelectModal}
        >
          {isModalOpen && (
            <BranchSelectForm onSuccessSelect={() => setIsModalOpen(false)} />
          )}
        </Modal>
      </CurrentAccount>
    );
  };

  if (
    location.pathname.includes(routes.login) ||
    location.pathname.includes(routes.registration) ||
    location.pathname.includes(routes.passwordReset.replace('/:code', '')) ||
    location.pathname.includes(routes.forgotPassword)
  ) {
    return <AuthLayout>{children}</AuthLayout>;
  }

  if (location.pathname.includes('/parent')) {
    return <ParentLayout>{children}</ParentLayout>;
  }

  return (
    <>
      <div className={styles.container}>
        {width && width >= MOBILE_BREAK_POINT && (
          <Sidebar
            navigationGroups={getNavigationGroups()}
            currentAccount={getCurrentBranch()}
            currentUser={currentUser}
          />
        )}
        <div
          className={cx(styles.rightSection, {
            [styles.noScroll]: isMobileMenuOpen,
          })}
        >
          <Navigation
            onDrawerClick={() => setIsMobileMenuOpen((prev) => !prev)}
            isMobileMenuOpen={isMobileMenuOpen}
          />
          <div className={styles.content}>{children}</div>
        </div>
      </div>
      {isMobileMenuOpen && width && width < MOBILE_BREAK_POINT && (
        <div className={styles.mobileDrawer}>
          {getCurrentBranch()}
          {getNavigationGroups().map((navigationGroup) => (
            <div className={styles.navigationGroup} key={navigationGroup.label}>
              <div className={styles.groupName}>{navigationGroup.label}</div>
              {navigationGroup.items
                .filter(
                  (item) =>
                    currentUser && item.roles.includes(currentUser.role),
                )
                .map((item) => (
                  <NavLink
                    key={item.label}
                    to={item.to}
                    className={({ isActive }) =>
                      cx(styles.navigationItem, {
                        [styles.activeSubItem]: isActive,
                      })
                    }
                  >
                    {item.label}
                  </NavLink>
                ))}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: StoreState) => ({
  selectedBranch: state.branch.selectedBranch,
  branchOptions: state.branch.branchOptions,
  currentUser: state.user.currentUser,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onBranchOptionsFetch: () => dispatch(locationService.fetchBranchOptions()),
  onBranchSelect: (branchId: number) =>
    dispatch(branchService.selectNewBranch(branchId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
