export const FETCH_DISCOUNT_START = 'FETCH_DISCOUNT_START';
export const FETCH_DISCOUNT_SUCCESS = 'FETCH_DISCOUNT_SUCCESS';
export const FETCH_DISCOUNT_FAIL = 'FETCH_DISCOUNT_FAIL';

export const CREATE_DISCOUNT_START = 'CREATE_DISCOUNT_START';
export const CREATE_DISCOUNT_SUCCESS = 'CREATE_DISCOUNT_SUCCESS';
export const CREATE_DISCOUNT_FAIL = 'CREATE_DISCOUNT_FAIL';

export const UPDATE_DISCOUNT_START = 'UPDATE_DISCOUNT_START';
export const UPDATE_DISCOUNT_SUCCESS = 'UPDATE_DISCOUNT_SUCCESS';
export const UPDATE_DISCOUNT_FAIL = 'UPDATE_DISCOUNT_FAIL';

export const DELETE_DISCOUNT_START = 'DELETE_DISCOUNT_START';
export const DELETE_DISCOUNT_SUCCESS = 'DELETE_DISCOUNT_SUCCESS';
export const DELETE_DISCOUNT_FAIL = 'DELETE_DISCOUNT_FAIL';

export const GET_PACKAGE_DISCOUNT_START = 'GET_PACKAGE_DISCOUNT_START';
export const GET_PACKAGE_DISCOUNT_SUCCESS = 'GET_PACKAGE_DISCOUNT_SUCCESS';
export const GET_PACKAGE_DISCOUNT_FAIL = 'GET_PACKAGE_DISCOUNT_FAIL';

export const RESET_DISCOUNT_STORE = 'RESET_DISCOUNT_STORE';
export const RESET_PARTIALLY_DISCOUNT_STORE = 'RESET_PARTIALLY_DISCOUNT_STORE';

export const LOGOUT = 'LOGOUT';

export type DiscountActionTypes =
  | typeof FETCH_DISCOUNT_START
  | typeof FETCH_DISCOUNT_SUCCESS
  | typeof FETCH_DISCOUNT_FAIL
  | typeof CREATE_DISCOUNT_START
  | typeof CREATE_DISCOUNT_SUCCESS
  | typeof CREATE_DISCOUNT_FAIL
  | typeof UPDATE_DISCOUNT_START
  | typeof UPDATE_DISCOUNT_SUCCESS
  | typeof UPDATE_DISCOUNT_FAIL
  | typeof DELETE_DISCOUNT_START
  | typeof DELETE_DISCOUNT_SUCCESS
  | typeof DELETE_DISCOUNT_FAIL
  | typeof GET_PACKAGE_DISCOUNT_START
  | typeof GET_PACKAGE_DISCOUNT_SUCCESS
  | typeof GET_PACKAGE_DISCOUNT_FAIL
  | typeof RESET_DISCOUNT_STORE
  | typeof RESET_PARTIALLY_DISCOUNT_STORE
  | typeof LOGOUT;
