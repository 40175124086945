import * as actionTypes from './actionTypes';
import { DiscountActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Discount } from '../../domain/Discount';
import { ListResults } from '../../common/List/List';

export type DiscountStateType = {
  discountCreateLoading: boolean;
  discountCreateError: HttpError;
  discountCreateSuccess: boolean;
  createdDiscount: Discount | null;
  discountUpdateLoading: boolean;
  discountUpdateError: HttpError;
  discountUpdateSuccess: boolean;
  discountDeleteLoading: boolean;
  discountDeleteError: HttpError;
  discountDeleteSuccess: boolean;
  discountsList: ListResults<Discount> | null;
  discountsLoading: boolean;
  discountsError: HttpError;
  discountsUpdateNeeded: boolean;
  packageDiscount: Discount | null;
  packageDiscountLoading: boolean;
  packageDiscountError: HttpError;
};

export type DiscountActionType = DiscountStateType & {
  type: DiscountActionTypes;
};

export const initialState: DiscountStateType = {
  discountCreateLoading: false,
  discountCreateError: null,
  discountCreateSuccess: false,
  createdDiscount: null,
  discountUpdateLoading: false,
  discountUpdateError: null,
  discountUpdateSuccess: false,
  discountDeleteLoading: false,
  discountDeleteError: null,
  discountDeleteSuccess: false,
  discountsUpdateNeeded: false,
  discountsList: null,
  discountsError: null,
  discountsLoading: false,
  packageDiscountError: null,
  packageDiscount: null,
  packageDiscountLoading: false,
};

const fetchDiscountsStart = (state: DiscountStateType): DiscountStateType => ({
  ...state,
  discountsLoading: true,
  discountsUpdateNeeded: false,
});

const fetchDiscountsSuccess = (
  state: DiscountStateType,
  action: DiscountActionType,
): DiscountStateType => ({
  ...state,
  discountsLoading: false,
  discountsError: null,
  discountsList: action.discountsList,
});

const fetchDiscountsFail = (
  state: DiscountStateType,
  action: DiscountActionType,
): DiscountStateType => ({
  ...state,
  discountsLoading: false,
  discountsError: action.discountsError,
});

const createDiscountStart = (state: DiscountStateType): DiscountStateType => ({
  ...state,
  discountCreateLoading: true,
});

const createDiscountSuccess = (
  state: DiscountStateType,
  action: DiscountActionType,
): DiscountStateType => ({
  ...state,
  discountCreateLoading: false,
  discountCreateError: null,
  discountCreateSuccess: true,
  createdDiscount: action.createdDiscount,
  discountsUpdateNeeded: true,
});

const createDiscountFail = (
  state: DiscountStateType,
  action: DiscountActionType,
): DiscountStateType => ({
  ...state,
  discountCreateLoading: false,
  discountCreateError: action.discountCreateError,
});

const updateDiscountStart = (state: DiscountStateType): DiscountStateType => ({
  ...state,
  discountUpdateLoading: true,
  discountUpdateSuccess: false,
});

const updateDiscountSuccess = (
  state: DiscountStateType,
): DiscountStateType => ({
  ...state,
  discountUpdateLoading: false,
  discountUpdateError: null,
  discountUpdateSuccess: true,
  discountsUpdateNeeded: true,
});

const updateDiscountFail = (
  state: DiscountStateType,
  action: DiscountActionType,
): DiscountStateType => ({
  ...state,
  discountUpdateLoading: false,
  discountUpdateError: action.discountUpdateError,
});

const deleteDiscountStart = (state: DiscountStateType): DiscountStateType => ({
  ...state,
  discountDeleteLoading: true,
});

const deleteDiscountSuccess = (
  state: DiscountStateType,
): DiscountStateType => ({
  ...state,
  discountDeleteLoading: false,
  discountDeleteError: null,
  discountDeleteSuccess: true,
  discountsUpdateNeeded: true,
});

const deleteDiscountFail = (
  state: DiscountStateType,
  action: DiscountActionType,
): DiscountStateType => ({
  ...state,
  discountDeleteLoading: false,
  discountDeleteError: action.discountDeleteError,
});

const getPackageDiscountStart = (
  state: DiscountStateType,
): DiscountStateType => ({
  ...state,
  packageDiscountLoading: true,
});

const getPackageDiscountSuccess = (
  state: DiscountStateType,
  action: DiscountActionType,
): DiscountStateType => ({
  ...state,
  packageDiscountError: null,
  packageDiscountLoading: false,
  packageDiscount: action.packageDiscount,
});

const getPackageDiscountFail = (
  state: DiscountStateType,
  action: DiscountActionType,
): DiscountStateType => ({
  ...state,
  packageDiscountLoading: false,
  packageDiscountError: action.packageDiscountError,
});

const resetPartiallyDiscountStore = (
  state: DiscountStateType,
): DiscountStateType => ({
  ...initialState,
  discountsList: state.discountsList,
});

const resetDiscountStore = (): DiscountStateType => ({
  ...initialState,
});

const completelyResetDiscountStore = (): DiscountStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: DiscountActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_DISCOUNT_START:
      return fetchDiscountsStart(state);
    case actionTypes.FETCH_DISCOUNT_SUCCESS:
      return fetchDiscountsSuccess(state, action);
    case actionTypes.FETCH_DISCOUNT_FAIL:
      return fetchDiscountsFail(state, action);
    case actionTypes.CREATE_DISCOUNT_START:
      return createDiscountStart(state);
    case actionTypes.CREATE_DISCOUNT_SUCCESS:
      return createDiscountSuccess(state, action);
    case actionTypes.CREATE_DISCOUNT_FAIL:
      return createDiscountFail(state, action);
    case actionTypes.UPDATE_DISCOUNT_START:
      return updateDiscountStart(state);
    case actionTypes.UPDATE_DISCOUNT_SUCCESS:
      return updateDiscountSuccess(state);
    case actionTypes.UPDATE_DISCOUNT_FAIL:
      return updateDiscountFail(state, action);
    case actionTypes.DELETE_DISCOUNT_START:
      return deleteDiscountStart(state);
    case actionTypes.DELETE_DISCOUNT_SUCCESS:
      return deleteDiscountSuccess(state);
    case actionTypes.DELETE_DISCOUNT_FAIL:
      return deleteDiscountFail(state, action);
    case actionTypes.GET_PACKAGE_DISCOUNT_START:
      return getPackageDiscountStart(state);
    case actionTypes.GET_PACKAGE_DISCOUNT_SUCCESS:
      return getPackageDiscountSuccess(state, action);
    case actionTypes.GET_PACKAGE_DISCOUNT_FAIL:
      return getPackageDiscountFail(state, action);
    case actionTypes.RESET_PARTIALLY_DISCOUNT_STORE:
      return resetPartiallyDiscountStore(state);
    case actionTypes.RESET_DISCOUNT_STORE:
      return resetDiscountStore();
    case actionTypes.LOGOUT:
      return completelyResetDiscountStore();
    default:
      return state;
  }
};

export default reducer;
